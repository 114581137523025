// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './assets/scss/_variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-primary-text: map-get($colors, 'text');
$light-primary-text: map-get($colors, 'text-white');

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$marketplace-frontend-primary: (
  50: #f3fafd,
  100: #dbf1fa,
  200: #b7e3f6,
  300: #94d5f1,
  400: #70c8ec,
  500: #1799cf,
  600: #15769f,
  700: #0e4f6a,
  800: #072735,
  900: #03141b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);
$marketplace-frontend-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$marketplace-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$marketplace-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($marketplace-frontend-primary),
      accent: $marketplace-frontend-accent,
      warn: $marketplace-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($marketplace-frontend-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

@import 'ngx-toastr/toastr';

@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-thumbnail';

@import './assets/scss/global.scss';
@import './assets/scss/typography.scss';
