/**
  * Heading
  */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 16px;
}

h1 {
  font-size: 36px;
  line-height: 44px;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 20px;
  line-height: 28px;
}

h6 {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 992px) {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 36px;
    line-height: 44px;
  }

  h3 {
    font-size: 32px;
    line-height: 40px;
  }

  h4 {
    font-size: 28px;
    line-height: 36px;
  }

  h5 {
    font-size: 24px;
    line-height: 32px;
  }

  h6 {
    font-size: 20px;
    line-height: 28px;
  }
}

/**
  * Paragraphs
  */
p {
  font-size: 16px;
  line-height: 24px;
}
