@import './_variables';

.property-info {
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: block;
  overflow: hidden;
  text-decoration: none;

  .images {
    position: relative;

    .property-type {
      position: absolute;
      left: 14px;
      bottom: 14px;
      z-index: 2;
    }
  }

  .info {
    padding: 10px 15px;

    header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .price {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
}

// So we can use it globally outside property-info elements
.property-type {
  background-color: #0f696e;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color: #fff;
  font-size: 13px;
  padding: 3px 8px;

  // &.subtype {
  background-color: lighten(#0f696ee0, 70%);
  color: #0f696e;
  // }
}

.property-info.property-details-info {
  background-color: transparent;
  box-shadow: none;
}
