$colors: (
  'primary': #1799cf,
  'secondary': #e57f55,

  // primary 900
  'text': #03141b,
  // neutral 700
  'text-gray': #465861,
  'text-light-gray': #698491,
  'text-blue': #0e4f6a,
  'text-white': #fafafa,
  'text-placeholder': #a4b0b5,

  'dark-slate-grey': #1e343f,
  'dark-blue': #0e4f6a,
  'light-blue': #dbf1fa,
  'bg-color': #fafafa,
  'bg-input': #f0f3f4,
  'border': #d1dade,
  'border-input': #efedeb,
  'border-input-active': #c2cdd3,

  'success': #28a745,
  'info': #17a2b8,
  'warning': #ffc107,
  'danger': #b9321e,

  'light': #f8f9fa,
  'gray': #f1f2f2,
  'dark-gray': #f4f5f5,
  'dark': #1d3541,
  'white': #fafafa,
  'black': #0e0e0e,
);

$border-radius: 6px;

$box-shadow:
  1px 5px 10px rgba(0, 0, 0, 0.04),
  1px 2px 5px rgba(0, 0, 0, 0.02);

$content-padding: 20px;
